:root {
    /*Margins & Paddings*/
    --padding: 1rem; 
    --margin: 1rem; 
    --vertical-padding: 1rem; /* must NOT be a percentage value */
    --vertical-margin: 1rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: "Hind", sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/fontmodules/Lato.css";
@import "/fontmodules/OpenSans.css";
@import "/fontmodules/Hind.css";
@import "/fontmodules/Roboto.css";


@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/cssmodules/animate.css";



/*
li.localeswitcher  {
    display: none;
}
*/

.fprint {
    text-align: center;
    font-size: 10pt;
    color: gray;
    margin: 0em 0 2em 0;
}

.spelare.button {
    display: block;
    margin: 1em 0px;
    border: none;
    background: #e4e4e4;
}
.spelareitem h2 {
    font-weight: 300;
}



.mfp-ajax-holder .wrapper{
    min-height: auto;
}

.mainAjaxContent :first-child{
    margin-top: 0;
}

.mfp-ajax-holder{
    background: #FFF;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
    height: calc(100% - 80px);
    overflow-y: auto;
}

.mfp-close {
    margin: 0;
}



.artikelbild {
    float: right;
    max-width: 40%;
    height: auto;
    margin: 0 0 15px 15px;
}

.moments .content-wrapper ul  {list-style-type:none; margin:0;padding:0;}
.moments .content-wrapper ul li  {margin:0 2% 2% 0;padding:20px; width: 100%; float:left; min-height:50px; background:#eee; }
.moments .content-wrapper ul li strong {font-size:1.2em; font-weight:400; margin: 0 0 5px;  }






.instagram img {
    filter: grayscale(100%);
    transition: filter 0.9s;

}
.instagram img:hover {
     filter: grayscale(0%);
}


/* ------------------------------- Meny players ---------------------------- */

nav.submenu ul {
    list-style-type: none;
    padding: 0px;
    margin-top: 0px;
}
nav.submenu ul li {
    padding: 20px 0px;
}
nav.submenu ul li a {
    font-size: 1.1em;
    color: #fff;
}
nav.submenu ul li:nth-child(even) {
    background: #222;
}
nav.submenu ul li:nth-child(odd) {
    background: #333;
}

.submenu {
    background: #fff;
}
.player .submenu .active {
    background: #908f8f !important;
}

.player h1 {
    margin-bottom: 0px;
}
/* .index-2 {
    background: #cac8c8;
}            
.index-3 {
    background: #d3d3d3;
}
-- */
/* ---------------------------------------------------------- */







.instatext a {
    display: inline-block;
    
}


.logo2>div img {
    max-height: 36px;
    width: auto;
    display: block;
    margin-bottom: -16px;
    margin-left: 8px;
}


/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/

body {
    font-family: "Roboto", "Hind", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    line-height: 1.5;
    font-weight: 300;
    color: #303030;
    background: rgb(235, 235, 235);
}
p {
    margin: 3px 0 5px;
}
a, a:hover {
    text-decoration: none;
    color: #303030;
}
img {
    max-width: 100%;
    height: auto;
    flex: none;
}
figure {
    margin: 0px;
}
.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}


/* ------------------------------- FONTS + HEADINGS ---------------------------- */
h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto";
    margin: 0.5em 0;  
}
.slick-title {
    font-size: 1.6rem;
    font-family: "Lato";
    font-weight: 600;
    text-align:center;
}

.slick-bildtext {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 15px;
  min-height: 38px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 50;
}

h1, h2 {
    font-weight: 300;
}

/* ------------- WRAPPERS ------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}

.site  {
    max-width: 1500px;
    margin: 0 auto;
    overflow-x:hidden;
}
/* .container.lrg {
    max-width: 1500px;
}

*/
.container {
    width: 100%; /*fixar issue med bredd i vissa flexelement i IE*/
}
.wrapper {
    min-height: calc(100vh - 80px);
    position: relative;
    background: #fff;
    /*margin-top: 80px; För att flytta ner innehåll vid position:fixed på header*/
}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/


.slick {
    margin-bottom: 0;
}

.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;
    
} 

.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: 50vh;
    width:100%;
    display: flex;
    background-size: cover;
    background-position: center center;
}





.startimage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 65vh;
}
.starttitle {
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    max-width: 50%;
    width: 30ch;
    line-height: 1;
    margin: 0 0 1em 0;
}
@media (min-width: 1100px){
    .starttitle {
        margin: 0 0 5em 0;
    }
}
.starttitle span{
    background: black;
    white-space: pre;
    display: inline-block;
}

.introtext {
    margin-bottom: 50px;
    margin-left: 25px;
    background: rgba(255, 255, 255, 0.74);
    padding: 4px 10px;
    font-weight: 400;
    font-size: 1.1em;

}

.introtext h2 {
    font-weight: 400;
    text-transform: uppercase;

}

.instatext {
    font-weight: 400;
}

/* ---------------SLIDER----------- */


.slick {
    margin-bottom: 0;
    
}

.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;
    
} 

.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: 70vh;
    width:100%;
    display: flex;
    background-size: cover;
    background-position: top;
}



/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
CONTENT -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

/* ----------------STARTTEXT------------  */



/* ------------------STARTBOXARNA----------- */
.startbox {
    height: 39vh;
    background-size: cover;
    background-position: center center;
}
a.boxwrapper {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    transition:background 0.5s ease-in 0s;
}

a.boxwrapper h2 {
    transition:color 0.5s ease-in 0s;
}

/* -----------------boxen HOVER ----------- */
.startbox:hover a.boxwrapper {
    background: rgba(109, 106, 106, 0.3);  
    transition:background 0.5s ease-in 0s;
}

/* -----------------boxen HOVER rubrik ----------- */
.startbox:hover a.boxwrapper h2 {
    color:#ffffff;
    transition:color 0.5s ease-in 0s;
}


/* ------------------PRODUKT/ER----------- */
.produktitem > div .button {
    background: black;
    color: white;
    border: none;

}








/*------------------------------------------------------------------------------------------------ 
HEADER -----------------------------------------------------------------------------------------*/

.logo2 > div img {
    max-height: 50px; 
    width: auto; 
}

/* ------------NAV + HEADER--------------- */
header {
    background: #fff;
    border-bottom: 1px solid #e4e4e4;
    z-index: 10;
    width: 100%;
    position: relative; /*om fixed el absolute, glöm ej margin-top på main*/
    top: 0;
    padding: 10px;
    box-shadow: 0 3px 3px rgba(0,0,0,.4);

}

.logo {
    padding: 10px 0px;
}
.logo img {
    max-height: 50px; 
    width: auto; 
}
.logo a, .logo img {
    display: block;
}

header nav {}
header nav ul.menu {}
header nav ul.menu li {}
header nav ul.menu li a {
    font-size: 1.1em;
    letter-spacing: 1px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 5px;
}
.menu.menu li a:hover, .menu.menu li.active a {
    color: #ff7600;
}

/*
nav ul.menu li.dropdown > a:after { content: "<"; display: inline-block; margin: 0 0 0 5px; }
*/

/*----------------------------MOBILMENY----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
        header nav ul.menu.show, header nav ul.menu.menu, header nav ul.menu li a,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
        background: #fff;
    }


}
    
    




/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/* -----------------FOOTER---------------- */
footer {
    background-color: #000;
    padding: 20px 0;
    color: white;
    text-align: center;
}
footer, footer a, footer a:hover { color:#fff; }
footer svg {
    margin-right: 10px;
    fill: white;
}
footer h4 {margin-top:0;}

/* -----------------Intendit-footer---------------- */
.created-by {
    background: #0c0c0c;
    text-align: center;
    padding: 10px 0; 
}
p.intenditfooter, p.intenditfooter a, p.intenditfooter a:hover  {
    color: #717171;
    line-height:1.1;
}
p.intenditfooter {
   margin:0;
}
/* -----------------KONTAKT---------------- */

.kontakt .kontaktcontain {
    text-align: center;
}

.kontakt .imagetop {
    min-height: 52vh;
}



/*------------------------------------------------------------------------------------------------ 
Nyheter & Nyhet--------------------------------------------------------------------------------*/
/* -----------------NYHETER---------------- */
.newsitem > div .button {
    background: #000;
    color: white;
    border: 1px solid black;
}

.personalitem img {
    border-radius: 50%;
}

.imagetop {
    min-height: 45vh;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
}


/* ----------------PAGINAION------------  */

div.pagination {
    margin: 2rem auto 0;
    padding-bottom: 1vh;
    list-style: none;
    width:100%;
    text-align: center;
}

ul.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

ul.pagination  li a {
    background: #000;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #404040;
}


/* --------------- Google maps --------------------*/
/* Give map a height and it's images no max-width */
.map-canvas {
    height: 43vh;
}

.map-canvas img {
    max-width: none;
}


/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/
/*----------------------------Allt under 1500px----------------------------*/
@media (max-width:1500px)
{
    .container.lrg {
        width: 96%; 
    } 
}
/*----------------------------Allt under 1300px----------------------------*/

@media (max-width:1300px)
{
    .container.med {
        width: 96%; 
    } 
}

/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
   
    .container {
        width: 96%; 
    } 
    .instagram img {
     filter: grayscale(0%);
}
    
  
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
/*Containers*/
    .container.sml {
        width: 96%; 
    } 
    
/*Footer*/   
    footer .s-twelve {
        text-align: center; 
    }
    footer .s-twelve a {
        display:block; 
        text-align:center; 
        margin: 0 auto; 
    } 
    
     .boltforms-l-left-row {
        max-width: 100%;
    }
}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
    
}



/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}


.slideout {
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    color: #333;
}
@media (min-width: 1100px){
    .slideout {
        position: absolute;
        bottom: 0%;
        right: 0;
        width: 50%;
        z-index: 10000;
        transition: transform .3s;
        transform: translateX(100%);
    }
}

.slideout.on {
  transform: translateX(-0%);
}

.slideout.on .sliderarrow{
    display: none;
}

.show-slide {
    position: absolute;
    top: 50%;
    left: 0px;
    z-index: 10000;
    transform: rotate(90deg) translateY(140%) translateX(-23%);
    background: #ff7600;
    color:white;
    border:none;
    border-radius:0;
    padding: 20px;
    margin: 0;
    text-transform: uppercase;
}

.calendar-inner {
    padding:2em;
}


.start-slik {
    position:relative;
}

.starttext {
    position: absolute;
    width: 100%;
    top: 70vh;
    transform: translateY(-100%);
}

.sliderarrow {
    top: 50%;
    transform: translateY(-50%) translateX(-140%);
    left: 0;
    position: absolute;
    fill: white;
}

.sliderarrow div{
    animation-duration: 3s;
    animation-fill-mode: both;
    animation-name: shake;
}


.playerinfo img{
    float: right;
    max-width: 40%;
    margin: 0px 0px 1em 1em;
}
.playerinfo {
    line-height: 2.5;
    
}
.playerinfo h1 {
  margin-top: 0px;    
}


.localeswitcher.active{
    display: none;
}
